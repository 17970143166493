import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1676ab2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-container" }
const _hoisted_2 = { class: "img-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "img-item",
        src: _ctx.item.imagePath
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.title), 1 /* TEXT */)
  ]))
}